<template>
  <main>
    <div class="modal-wrap">
      <h1 class="tit-h1 d-flex">
        챗봇 관리
        <!-- 창 닫기 아이콘 -->
        <div class="ml-auto align-self-center">
          <v-icon class="svg-close20" x-small @click="$emit('hide')"></v-icon>
        </div>
      </h1>
      <!-- 조회 -->
      <div class="modal-box d-flex">
        <div class="table-form">
          <table>
            <caption class="hide">
              조회 항목
            </caption>
            <colgroup>
              <col width="148px" />
              <col width="180px" />
              <col width="148px" />
              <col width="350px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  건설사명
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="CTCP_NAME"
                    name="CTCP_NAME"
                    outlined
                    hide-details
                    disabled
                  >
                  </v-text-field>
                </td>
                <th scope="row">
                  사업지명
                </th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="BUSS_NAME"
                    name="BUSS_NAME"
                    outlined
                    hide-details
                    disabled
                  >
                  </v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 저장 버튼 -->
        <div class="ml-auto align-self-center">
          <v-btn outlined small class="btn-default ml-2" @click="newEditSaveBtn">저장</v-btn>
        </div>
      </div>
      <!-- 입력 항목 -->
      <div class="modal-box flex-grow-1">
        <span class="noti-font"><strong>※</strong> 필수 정보 및 사용하는 모든 블록의 정보를 입력해야 정상적으로 챗봇 사용이 가능합니다. 블록의 정보는 카카오 비즈니스의 각 블록 주소창에서 확인 가능합니다.</span>
        <div class="box-ct mt-2">
          <v-form ref="form" lazy-validation>
            <div class="table-form">
              <table>
                <caption class="hide">
                  항목
                </caption>
                <colgroup>
                  <col width="200px" />
                  <col width="" />
                  <col width="200px" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        봇ID
                      </v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BOT_ID"
                        name="BOT_ID"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      <v-badge dot color="#EF9191">
                        카카오API키
                      </v-badge>
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_API_KEY"
                        name="BLOCK_API_KEY"
                        outlined
                        hide-details
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      방문예약 이미지
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full table-form-tag form-image"
                        v-model="VISIT_IMAGE"
                        name="VISIT_IMAGE" 
                        outlined 
                        hide-details
                        accept="image/*"
                        type="file"
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      이벤트 이미지
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full table-form-tag form-image"
                        v-model="EVENT_IMAGE"
                        name="EVENT_IMAGE" 
                        outlined 
                        hide-details
                        accept="image/*"
                        type="file"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      방문예약 리스트
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_VISIT_01"
                        name="BLOCK_VISIT_01"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      방문예약 취소
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_VISIT_02"
                        name="BLOCK_VISIT_02"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      이벤트 리스트
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_EVENT_01"
                        name="BLOCK_EVENT_01"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      이벤트 취소
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_EVENT_02"
                        name="BLOCK_EVENT_02"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      메인 케로셀
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_COM_01"
                        name="BLOCK_COM_01"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      이름 수정
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_COM_02"
                        name="BLOCK_COM_02"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      인증 블록
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_COM_03"
                        name="BLOCK_COM_03"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                    <th scope="row">
                      인증 확인
                    </th>
                    <td>
                      <v-text-field
                        class="form-inp full"
                        v-model="BLOCK_COM_04"
                        name="BLOCK_COM_04"
                        outlined
                        hide-details
                        placeholder="사용 시 입력하세요."
                      >
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import axios from "axios";

  export default {
    name: "MENU_BUP0110", //name은 'MENU_' + 파일명 조합
    components: {},
    props : {
      param: {
        type: Object,
      },
    },
    data() {
      return {
        HEADERResult: '',
        DATAResult  : '',
        
        HEADER_TYPE     : 'BIZ_SERVICE',
        HEADER_GRID_ID  : '',
        HEADER_ROW_CNT  : '',
        HEADER_PAGES_CNT: '',
        headers         : {},
        
        URLData : '',
        sendData: {},

        // 공통코드
        groupCdList: ['HLW_SVC_PRVD_CD', 'HLW_REL_TABLE_CD', 'HLW_REL_TYPE_CD'],
        allCodeList: [],
        svcPrvdCd  : [],
        relTableCd : [],
        relTypeCd  : [],

        // 전달 받은 값 담을 변수
        DATA_FLAG: '', // 처리구분
        CTCP_CODE: '', // 건설사코드
        CTCP_NAME: '', // 건설사명
        BUSS_CODE: '', // 사업지코드
        BUSS_NAME: '', // 사업지명
        BOTID    : '', // 챗봇아이디

        // 팝업 내용
        BOT_ID        : '', // 봇ID
        BLOCK_API_KEY : '', // 카카오 API 키
        BLOCK_VISIT_01: '', // 방문예약 리스트
        BLOCK_VISIT_02: '', // 방문예약 취소
        BLOCK_EVENT_01: '', // 이벤트 리스트
        BLOCK_EVENT_02: '', // 이벤트 취소
        BLOCK_COM_01  : '', // 메인 케로셀
        BLOCK_COM_03  : '', // 인증블록
        BLOCK_COM_02  : '', // 이름수정
        BLOCK_COM_04  : '', // 인증확인

        // 이미지
        imgSrc         : '/buss/manage/images/',
        token          : '',
        response       : '',
        saveKeysObj    : [],
        VISIT_IMAGE    : '', // 방문예약 이미지
        EVENT_IMAGE    : '', // 이벤트 이미지
        svc_prvd_cd    : '', // 서비스 제공자 코드
        rel_table_cd   : '', // 연결 테이블 코드
        rel_type_cd_arr: [], // 연결 타입 코드
        VISIT_UPLD_ID  : '', // 방문예약 이미지 업로드ID
        EVENT_UPLD_ID  : '', // 이벤트 이미지 업로드ID
        editUpldImgData: [],

        // 기타
        USER_ID: '', // 사용자아이디

        // 메시지
        bup0110Msg: {
          saveChk            : '챗봇을 등록하시겠습니까?',
          editChk            : '챗봇을 수정하시겠습니까?',
          saveSuccess        : '저장되었습니다.',
          editSuccess        : '수정되었습니다.',
          botIdEmptyChk      : '봇 ID를 입력하세요.',
          blockApiKeyEmptyChk: '카카오 API 키를 입력하세요.',
          saveErrorChk       : '시스템 장애가 일어났습니다.<br>관리자에게 문의하세요.',
        },
      };
    },
    methods: {
      // 상세 조회
      async init() {
        this.cleanData(); // 초기화
        
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };
        
        // 부모 창에서 넘어온 값 담기
        this.DATA_FLAG = this.param.dataFlag;
        this.CTCP_CODE = this.param.ctcpCd;
        this.CTCP_NAME = this.param.ctcpNm;
        this.BUSS_CODE = this.param.bussCd;
        this.BUSS_NAME = this.param.bussNm;
        this.BOTID = this.param.botId;

        requestData.headers["URL"] = "/api/buss/buss-manage/chatbot/detail";
        requestData.headers["SERVICE"] = "buss.buss-manage.chatbot";
        requestData.headers["METHOD"] = "detail";
        requestData.headers["TWB_SQL_ID"] = "selectChatbotDetail";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["BOT_ID"] = this.BOTID;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 챗봇 상세 조회 결과
      getGridDataDetailCallBack(response) {
        let detailData = response.DATA;

        this.BOT_ID = detailData[0].BOT_ID;
        this.BLOCK_API_KEY = detailData[0].BLOCK_API_KEY;
        this.VISIT_UPLD_ID = detailData[0].VISIT_UPLD_ID;
        this.EVENT_UPLD_ID = detailData[0].EVENT_UPLD_ID;
        this.imagesName(detailData[0].VISIT_IMAGE, detailData[0].EVENT_IMAGE);
        this.BLOCK_VISIT_01 = detailData[0].BLOCK_VISIT_LIST;
        this.BLOCK_EVENT_01 = detailData[0].BLOCK_EVENT_LIST;
        this.BLOCK_VISIT_02 = detailData[0].BLOCK_VISIT_CANCEL;
        this.BLOCK_EVENT_02 = detailData[0].BLOCK_EVENT_CANCEL;
        this.BLOCK_COM_01 = detailData[0].BLOCK_COM_MAIN_LIST;
        this.BLOCK_COM_03 = detailData[0].BLOCK_COM_PROFILE;
        this.BLOCK_COM_02 = detailData[0].BLOCK_COM_MODIFY;
        this.BLOCK_COM_04 = detailData[0].BLOCK_COM_CHECK;
      },

      // 챗봇 등록/수정 여부 확인
      newEditSaveBtn() {
        // 유효성 체크
        if(!this.saveValidate()) {
          return;
        }

        if(this.DATA_FLAG === 'I') {
          this.common_confirm(this.bup0110Msg.saveChk, this.fileSave, '', null, null, "done");
        }else if(this.DATA_FLAG === 'U') {
          this.common_confirm(this.bup0110Msg.editChk, this.fileSave, '', null, null, "done");
        }
      },

      // 파일 업로드 및 데이터 전송
      fileSave() {
        this.token = this.mixin_getToken(); //authToken 발행

        let requestData = {
          headers: {
            SERVICE: 'files',
            METHOD: 'upload',
            TYPE: this.HEADER_TYPE,
            URLData: process.env.VUE_APP_API_URL + "api/files/upload",
            AUTHORIZATION: this.token,
            UPLOAD_URI: "/api/files/upload",
            DOWNLOAD_URI: "/api/files/download",
            DIR: this.imgSrc, // 파일 저장 경로
            TEMP_DIR: this.imgSrc + "/temp",
            PROC_ID: this.USER_ID,
            PATH_TYPE: 'images',
            REL_ID: this.BUSS_CODE,
            UPLD_GROUP_ID: this.UPLD_GROUP_ID,
            'Content-Type': 'multipart/form-data',
          },
          sendData: {},
        };

        let vue = this;
        let localSaveKeysObj = [];
        let files = vue.$refs.form.inputs;
        let sknObj = {};
        let rel_type_cds = [];
        const formData = new FormData();

        for (var i = 0; i < files.length; i++) {
          if(files[i].type === 'file' && files[i].$refs.input.files[0] !== undefined) {
            if(this.fileExtTypeChk(files[i].$refs.input.files[0].name)) {
              // .png,.jpg,.gif,.jpeg........ 이미지 타입 확장자 유무 체크
              formData.append('fileList', files[i].$refs.input.files[0]);
              sknObj = {};
              sknObj['key'] = files[i].$refs.input.name;
              sknObj['fileName'] = files[i].$refs.input.files[0].name;
              localSaveKeysObj.push(sknObj); // 업로드할 파일 적재

              sknObj={};
              sknObj['key'] = files[i].$refs.input.files[0].name;
              sknObj['value'] = this.rel_type_cd_arr[files[i].$refs.input.name];
              rel_type_cds.push(sknObj);

              // 파일 변경 시 기존 파일 정보 담기
              if(this.DATA_FLAG === 'U' && localSaveKeysObj.length > 0) {
                sknObj = '';

                if(files[i].$refs.input.name === 'VISIT_IMAGE') {
                  sknObj += this.VISIT_UPLD_ID;
                }else if(files[i].$refs.input.name === 'EVENT_IMAGE') {
                  sknObj += this.EVENT_UPLD_ID;
                }
                this.editUpldImgData.push(sknObj);
              }
            }
          }
        }
        this.saveKeysObj = localSaveKeysObj;

        if(this.saveKeysObj.length > 0) {
          formData.append("SVC_PRVD_CD", this.svc_prvd_cd);
          formData.append("REL_TABLE_CD", this.rel_table_cd);
          formData.append("REL_TYPE_CDS", JSON.stringify(rel_type_cds));

          axios.post(requestData.headers.URLData, formData, {
            headers: requestData.headers
          }).then(response => {
            const returnDatas = response.data;

            if(returnDatas.responseMessage === '정상') {
              // 파일 업로드 끝...
              this.newEditSave(); // 파일이 아닌 다른 데이터 저장 부분
            }else{
              return this.common_alert(this.bup0110Msg.saveErrorChk, "error");
            }
          }).catch(error => {
            return error.message;
          })
        }else{
          this.newEditSave();
        }
      },

      // 등록/수정 저장
      async newEditSave() {
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/buss-manage/chatbot/regist";
        requestData.headers["SERVICE"] = "buss.buss-manage.chatbot";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        if(this.DATA_FLAG === 'I') {
          requestData.headers["TWB_SQL_ID"] = "insertChatbotNewInfo";

          requestData.sendData["DATA_FLAG"] = this.DATA_FLAG;
          requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
          requestData.sendData["BOT_ID"] = this.BOT_ID
          requestData.sendData["BLOCK_API_KEY"] = this.BLOCK_API_KEY
          requestData.sendData["BLOCK_VISIT_01"] = this.BLOCK_VISIT_01
          requestData.sendData["BLOCK_EVENT_01"] = this.BLOCK_EVENT_01
          requestData.sendData["BLOCK_VISIT_02"] = this.BLOCK_VISIT_02
          requestData.sendData["BLOCK_EVENT_02"] = this.BLOCK_EVENT_02
          requestData.sendData["BLOCK_COM_01"] = this.BLOCK_COM_01
          requestData.sendData["BLOCK_COM_03"] = this.BLOCK_COM_03
          requestData.sendData["BLOCK_COM_02"] = this.BLOCK_COM_02
          requestData.sendData["BLOCK_COM_04"] = this.BLOCK_COM_04
          requestData.sendData["REG_ID"] = this.USER_ID;
          requestData.sendData["UPD_ID"] = this.USER_ID;
        }else if(this.DATA_FLAG === 'U') {
          requestData.headers["TWB_SQL_ID"] = "updateChatbotInfo";

          requestData.sendData["DATA_FLAG"] = this.DATA_FLAG;
          requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
          requestData.sendData["BOT_ID"] = this.BOTID       // 원본 봇아이디
          requestData.sendData["EDIT_BOT_ID"] = this.BOT_ID // 수정한 봇아이디
          requestData.sendData["BLOCK_API_KEY"] = this.BLOCK_API_KEY
          requestData.sendData["EDIT_UPLD_IDS"] = this.editUpldImgData.join();
          requestData.sendData["BLOCK_VISIT_01"] = this.BLOCK_VISIT_01
          requestData.sendData["BLOCK_EVENT_01"] = this.BLOCK_EVENT_01
          requestData.sendData["BLOCK_VISIT_02"] = this.BLOCK_VISIT_02
          requestData.sendData["BLOCK_EVENT_02"] = this.BLOCK_EVENT_02
          requestData.sendData["BLOCK_COM_01"] = this.BLOCK_COM_01
          requestData.sendData["BLOCK_COM_03"] = this.BLOCK_COM_03
          requestData.sendData["BLOCK_COM_02"] = this.BLOCK_COM_02
          requestData.sendData["BLOCK_COM_04"] = this.BLOCK_COM_04
          requestData.sendData["UPD_ID"] = this.USER_ID;
        }
        
        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 등록/수정 저장 결과
      getGridDataSaveCallBack(response) {     
        if(this.DATA_FLAG === 'I') {
          if(!response.HEADER.ERROR_FLAG) {
            this.common_alert(this.bup0110Msg.saveSuccess);
          }else {
            // 봇ID 중복
            this.common_alert(response.HEADER.ERROR_MSG, "error");
            return;
          }
        }else if(this.DATA_FLAG === 'U') {
          this.common_alert(this.bup0110Msg.editSuccess);
        }

        let params = {
          CTCP_CD: this.CTCP_CODE,
          BUSS_CODE: this.BUSS_CODE,
        };

        this.$emit('hide'); // 팝업 닫기
        this.$emit('select', params); // 부모 창으로 값 전달
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "regist") {
          this.getGridDataSaveCallBack(response);
        }else if(response.HEADER.METHOD === "detail" && response.HEADER.TWB_SQL_ID === "selectChatbotDetail") {
          this.getGridDataDetailCallBack(response);
        }
      },

      // 초기화
      cleanData() {
        this.BOT_ID = '';
        this.BLOCK_API_KEY = '';
        this.BLOCK_VISIT_01 = '';
        this.BLOCK_EVENT_01 = '';
        this.BLOCK_VISIT_02 = '';
        this.BLOCK_EVENT_02 = '';
        this.BLOCK_COM_01 = '';
        this.BLOCK_COM_03 = '';
        this.BLOCK_COM_02 = '';
        this.BLOCK_COM_04 = '';
      },

      // 유효성 체크
      saveValidate() {
        if(this.BOT_ID === '') {
          this.common_alert(this.bup0110Msg.botIdEmptyChk, "error");
          return;
        }

        if(this.BLOCK_API_KEY === '') {
          this.common_alert(this.bup0110Msg.blockApiKeyEmptyChk, "error");
          return;
        }
        return true;
      },

      // 파일 타입 체크
      fileExtTypeChk(fileName) {
        let boolParam = false;

        for(let i = 0; i < this.geImgExtArry.length; i++) {
          if(fileName.indexOf(this.geImgExtArry[i]) !== -1) {
            boolParam = true;
          }
        }
        return boolParam;
      },

      // 파일 업로드 관련 공통코드
      getCommonCode(groupCd) {
        if(groupCd === 'HLW_SVC_PRVD_CD') {
          for(let i = 0; i < this.svcPrvdCd.length; i++) {
            if(process.env.VUE_APP_API_URL.indexOf('local') !== -1) {
              if(this.svcPrvdCd[i].CD_NM.indexOf('local') !== -1) {
                this.svc_prvd_cd = this.svcPrvdCd[i].CD;
              }
            }else {
              if(this.svcPrvdCd[i].CD_NM.indexOf('local') <= -1) {
                this.svc_prvd_cd = this.svcPrvdCd[i].CD;
              }
            }
          }
        }else if(groupCd === 'HLW_REL_TABLE_CD') {
          for(let i = 0; i < this.relTableCd.length; i++) {
            if(this.relTableCd[i].CD_NM.indexOf('챗봇') !== -1) {
              this.rel_table_cd = this.relTableCd[i].CD;
            }
          }
        }else {
          for(let i = 0; i < this.relTypeCd.length; i++) {
            if(this.relTypeCd[i].CD_NM.indexOf('visit') !== -1) {
              this.rel_type_cd_arr["VISIT_IMAGE"] = this.relTypeCd[i].CD;
            }else if(this.relTypeCd[i].CD_NM.indexOf('event') !== -1) {
              this.rel_type_cd_arr["EVENT_IMAGE"] = this.relTypeCd[i].CD;
            }
          }
        }
      },

      // 파일명 표기 위치 생성
      createElFileName() {
        const createEl1 = document.createElement('h4');
        document.querySelector("[name=VISIT_IMAGE]").closest('td').appendChild(createEl1);

        const createEl2 = document.createElement('h4');
        document.querySelector("[name=EVENT_IMAGE]").closest('td').appendChild(createEl2);
      },

      // 파일명 표기
      imagesName(visit, event, subscription, first) {
        const visitImage = document.querySelector("[name=VISIT_IMAGE]").closest('td');
        visitImage.childNodes[1].textContent = visit;

        const eventImage = document.querySelector("[name=EVENT_IMAGE]").closest('td');
        eventImage.childNodes[1].textContent = event;
      },
    },
    async mounted() {
      this.USER_ID = this.user_id;
      this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
      this.svcPrvdCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_SVC_PRVD_CD');
      this.relTableCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_REL_TABLE_CD');
      this.relTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_REL_TYPE_CD');
      this.getCommonCode('HLW_SVC_PRVD_CD');
      this.getCommonCode('HLW_REL_TABLE_CD');
      this.getCommonCode('HLW_REL_TYPE_CD');
      this.createElFileName();

      if(this.param.inputState != 'N') {
        this.init();
      }else {
        this.cleanData(); // 초기화

        // 부모 창에서 넘어온 값 담기
        this.DATA_FLAG = this.param.dataFlag;
        this.CTCP_CODE = this.param.ctcpCd;
        this.CTCP_NAME = this.param.ctcpNm;
        this.BUSS_CODE = this.param.bussCd;
        this.BUSS_NAME = this.param.bussNm;
      }
    },
    computed: {
      initHeaders() {
        return this.headers = {
          "SERVICE": "",
          "METHOD": "",
          "TWB_SQL_ID": "",
          "TYPE": this.HEADER_TYPE,
        };
      },
    },
  };
</script>

<style scoped>
  .noti-font {
    font-size: 12px;
    color: red;
  }
</style>